<template>
  <div id="container">

    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex on">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>入塾金の<br>支払いへ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>入力内容の確認</span></h2>
            <p class="reg_lead">
          <span class="blue bold">すべての入力内容に間違いがないことをご確認ください。<br>
            すべての内容を確認の後、画面下の「会員登録を完了する」ボタンを押して会員登録を完了してください。</span><br><br>
              入力内容を修正する場合は、該当する項目をクリックして正しい内容を再度入力してください。<br><br>
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <form-error-box v-if="errors"/>

            <div class="reg_set">
              <div class="input_set">
                <div class="label">ご希望のクラス・お支払い方法<span>*</span></div>
                <div class="class_check">
                  <div class="select_class">
                    <dl class="flex">
                      <dt>現在ご希望のクラス</dt>
                      <dd class="class">{{ rank_name }}</dd>
                    </dl>
                    <dl class="flex">
                      <dt>お支払い形式</dt>
                      <dd class="payment">{{ rank_payment }}</dd>
                    </dl>
                  </div>

                  <div class="acc_menu">
                    <div class="acc_tab">
                      <span></span>
                      <span style="font-size:0.8rem;">&nbsp;※クラスの変更は入塾後も可能です。</span>
                    </div>
                    <div class="acc_cts" style="display: none;">
                      <div class="class_list_box">
                        <div class="class_list flex head">
                          <div class="class">クラス<br class="sp">・お支払い形式</div>
                          <div class="price">お支払い金額(税込)</div>
                          <div class="check">&nbsp;</div>
                          <div class="btn">変更する<br class="sp">場合はクリック</div>
                        </div>

                        <div class="class_list flex check_radio class01">
                          <input type="radio" id="rank_regular_plus_month" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_plus_month" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_plus_month">
                            <div class="class"><span>本科プラス</span>月額払い</div>
                            <div class="price">¥27,500/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class01">
                          <input type="radio" id="rank_regular_plus_year" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_plus_year" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_plus_year">
                            <div class="class"><span>本科プラス</span>年額払い</div>
                            <div class="price">¥22,000/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class02">
                          <input type="radio" id="rank_regular_month" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_month" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_month">
                            <div class="class"><span>本科</span>月額払い</div>
                            <div class="price">¥16,500/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class02">
                          <input type="radio" id="rank_regular_year" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_year" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_year">
                            <div class="class"><span>本科</span>年額払い</div>
                            <div class="price">¥13,500/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class03">
                          <input type="radio" id="rank_preparatory_month" name="支払い方法" v-model="new_user.rank"
                                 value="rank_preparatory_month" v-on:change="rankChange()">
                          <label class="flex" for="rank_preparatory_month">
                            <div class="class"><span>予科</span>月額払い</div>
                            <div class="price">¥4,950/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>
                      </div>

                      <div class="class_list_box shikihou" v-bind:class="{no_option: no_option}" v-show="show_shikihou">
                        <div class="shikihou_ttl flex">
                          <h4>東洋経済新報社の「会社四季報ONLINE」の優待プランを追加できます（本科・年額払いのみ）</h4>
                          <p class="tax_caution bold red">※表示はすべて税込価格です</p>
                        </div>

                        <div class="class_list flex check_radio">
                          <input type="radio" id="course04" name="コース" v-model="new_user.shikiho" value=""
                                 v-on:change="shikihoChange()">
                          <label class="flex" for="course04">
                            <div class="shikihou_option min">会社四季報ONLINE の優待プランを選択しない</div>
                            <div class="price pc">&nbsp;</div>
                            <div class="btn"><span></span></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio">
                          <input type="radio" id="rank_regular_shikiho_premium_year" name="コース"
                                 v-model="new_user.shikiho"
                                 value="rank_regular_shikiho_premium_year"
                                 v-on:change="shikihoChange()">
                          <label class="flex" for="rank_regular_shikiho_premium_year">
                            <div class="shikihou_option">四季報ONLINE プレミアムプランを追加する</div>
                            <div class="price">+¥50,000/年</div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div><!--no_option の時は「選択不可」に変更-->
                          </label>
                        </div>

                        <div class="class_list flex check_radio">
                          <input type="radio" id="rank_regular_shikiho_basic_year" name="コース" v-model="new_user.shikiho"
                                 value="rank_regular_shikiho_basic_year"
                                 v-on:change="shikihoChange()">
                          <label class="flex" for="rank_regular_shikiho_basic_year">
                            <div class="shikihou_option">四季報ONLINE ベーシックプランを追加する</div>
                            <div class="price">＋¥12,000/年</div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div><!--no_option の時は「選択不可」に変更-->
                          </label>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="input_set name">
                <div class="label">おなまえ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓</div>
                    <input type="text" maxlength="16" v-model="new_user.last_name"
                           v-bind:class="{error: validation_errors.last_name}"
                           @input="new_user.last_name = replaceHalfToFull(new_user.last_name)"
                           placeholder="例：山田">
                    <validation-errors :errors="validation_errors.last_name"
                                       v-if="validation_errors.last_name"></validation-errors>
                  </div>
                  <div class="field">
                    <div class="label">名</div>
                    <input type="text" maxlength="16" v-model="new_user.first_name"
                           v-bind:class="{error: validation_errors.first_name}"
                           @input="new_user.first_name = replaceHalfToFull(new_user.first_name)"
                           placeholder="例：太郎">
                    <validation-errors :errors="validation_errors.first_name"
                                       v-if="validation_errors.first_name"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">フリガナ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓（全角カナ）</div>
                    <input type="text" maxlength="16" v-model="new_user.last_name_kana"
                           @input="new_user.last_name_kana = replaceHiraToKana(new_user.last_name_kana)"
                           v-bind:class="{error: validation_errors.last_name_kana}" placeholder="例：ヤマダ">
                    <validation-errors :errors="validation_errors.last_name_kana"
                                       v-if="validation_errors.last_name_kana"></validation-errors>
                  </div>
                  <div class="field">
                    <div class="label">名（全角カナ）</div>
                    <input type="text" maxlength="16" v-model="new_user.first_name_kana"
                           @input="new_user.first_name_kana = replaceHiraToKana(new_user.first_name_kana)"
                           v-bind:class="{error: validation_errors.first_name_kana}" placeholder="例：タロウ">
                    <validation-errors :errors="validation_errors.first_name_kana"
                                       v-if="validation_errors.first_name_kana"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set">
                <div class="label">性別<span>*</span></div>
                <div class="radio" v-bind:class="{error: validation_errors.gender}">
                  <input id="radio01" type="radio" v-model="new_user.gender" value="male">
                  <label for="radio01">男性</label>

                  <input id="radio02" type="radio" v-model="new_user.gender" value="female">
                  <label for="radio02">女性</label>

                  <input id="radio03" type="radio" v-model="new_user.gender" value="secret">
                  <label for="radio03">その他</label>
                </div>
                <validation-errors :errors="validation_errors.gender"
                                   v-if="validation_errors.gender"></validation-errors>
              </div>

              <div class="input_set birth">
                <div class="label">生年月日<span>*</span></div>
                <p>すべての欄に半角数字でご入力下さい。</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_year"
                           @input="new_user.birthday_year = replaceFullToHalf(new_user.birthday_year)"
                           v-bind:class="{error: validation_errors.birthday_year}" placeholder="年">
                    <validation-errors :errors="validation_errors.birthday_year"
                                       v-if="validation_errors.birthday_year"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_month"
                           @input="new_user.birthday_month = replaceFullToHalf(new_user.birthday_month)"
                           v-bind:class="{error: validation_errors.birthday_month}" placeholder="月">
                    <validation-errors :errors="validation_errors.birthday_month"
                                       v-if="validation_errors.birthday_month"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_day"
                           @input="new_user.birthday_day = replaceFullToHalf(new_user.birthday_day)"
                           v-bind:class="{error: validation_errors.birthday_day}" placeholder="日">
                    <validation-errors :errors="validation_errors.birthday_day"
                                       v-if="validation_errors.birthday_day"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">ユーザーネーム（表示名）<span>*</span></div>
                <p>複眼経済塾WEBサイト上で他の塾生に表示されるお名前です。ログイン後に「マイページ」で変更できます。</p>
                <input type="text" class="nickname" v-bind:class="{error: validation_errors.humhub_username}"
                       v-model="new_user.humhub_username">
                <ValidationErrors :errors="validation_errors.humhub_username"
                                  v-if="validation_errors.humhub_username"></ValidationErrors>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス<span>*</span></div>
                <input type="text" class="email" v-bind:class="{error: validation_errors.email}"
                       v-model="new_user.email">
                <ValidationErrors :errors="validation_errors.email"
                                  v-if="validation_errors.email"></ValidationErrors>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス(確認)<span>*</span></div>
                <input type="text" class="email" v-bind:class="{error: validation_errors.email_confirmation}"
                       v-model="new_user.email_confirmation">
                <ValidationErrors :errors="validation_errors.email_confirmation"
                                  v-if="validation_errors.email_confirmation"></ValidationErrors>
              </div>

              <div class="input_set phone">
                <div class="label">連絡先電話番号<span>*</span></div>
                <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
                <input type="tel" v-model="new_user.phone_number" v-bind:class="{error: validation_errors.phone_number}"
                       @input="new_user.phone_number = replaceFullToHalf(new_user.phone_number)"
                       placeholder="例：09012345678">
                <validation-errors :errors="validation_errors.phone_number"
                                   v-if="validation_errors.phone_number"></validation-errors>
              </div>

              <div class="input_set email" v-if="showShikihoAccount()">
                <div class="label">東洋経済新報社の「会社四季報ONLINE（有償版）」にすでに加入している方はチェックを入れてください。</div>
                <div class="checkbox">
                  <input id="sns_notice02" type="checkbox" v-model="show_shikiho_account"
                         @change="changeShikihoAccount()">
                  <label for="sns_notice02" class="flex">
                    会社四季報ONLINE（有償版）加入済み
                  </label>
                </div>
                <template v-if="show_shikiho_account">
                  <p class="mt5">
                    <span class="bold">年間契約の残存期間がある方は返金を行います。下記の欄に会社四季報ONLINEのログイン用ID（メールアドレス）を入力してください。</span><br>
                    後日、返金のための銀行口座を事務局よりおうかがいします。<br>
                    「会社四季報ONLINE（<span class="red bold">ゲスト・無料会員</span>）」の方は<span class="red bold">返金がないため対象外</span>です。<br>
                    <span class="red bold">「会社四季報ONLINE」のIDをお持ちでない方は何も入力しないでください。</span>IDの確認方法は<a href="https://www.millioneyes.jp/shikiho_online_id/" target="_blank"><span class="bold">こちら。</span></a>
                  </p>
                  <div class="label" style="vertical-align: middle">
                    <img src="/common/images/shikiho_logo.svg" style="width: 100px; vertical-align: middle" alt="">
                    会社四季報ONLINEログイン用ID（メールアドレス）
                  </div>
                  <input type="text" class="email" v-model="new_user.shikiho_account"
                         v-bind:class="{error: validation_errors.shikiho_account}"
                         @input="new_user.shikiho_account = replaceFullToHalf(new_user.shikiho_account)"
                         placeholder="">
                  <div class="shikiho_account_guide">
                    （１）すでにご利用いただいている「会社四季報ONLINE」の年間契約が残っている場合は塾生割引と重複する期間分をご返金いたします。<br>
                    （２）すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）を塾生割引のログイン用メールアドレス に&nbsp;<span class="red bold">紐付け</span>&nbsp;を行います。（紐付けを行うことにより各種の設定や銘柄等の設定を引き継ぐことができます）<br>
                    （３） 紐付けが完了すると、すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）でログインできるようになります。（紐付けが完了するまではすでにご利用いただいている ログイン用メールアドレス 、塾生割引ログイン用メールアドレス ともにお使いいただけます）<br>
                  </div>
                </template>
              </div>

              <div class="input_set addr">
                <div class="label">郵便番号<span>*</span></div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.zip" v-bind:class="{error: validation_errors.zip}" name="zip"
                           @input="new_user.zip = replaceFullToHalf(new_user.zip)"
                           placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.zip" v-if="validation_errors.zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名<span>*</span></div>
                <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>
                <PrefectureSelect :prefecture="new_user.prefecture" name="prefecture"
                                  v-bind:class="{error: validation_errors.prefecture}"
                                  v-on:changed="prefectureChanged"/>
                <validation-errors :errors="validation_errors.prefecture"
                                   v-if="validation_errors.prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村<span>*</span></div>
                <input type="text" v-model="new_user.address1" v-bind:class="{error: validation_errors.address1}"
                       @input="new_user.address1 = replaceHalfToFull(new_user.address1)"
                       name="address1" placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.address1"
                                   v-if="validation_errors.address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地<span>*</span></div>
                <input type="text" v-model="new_user.address2" v-bind:class="{error: validation_errors.address2}"
                       @input="new_user.address2 = replaceHalfToFull(new_user.address2)"
                       placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.address2"
                                   v-if="validation_errors.address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="new_user.address3" v-bind:class="{error: validation_errors.address3}"
                       @input="new_user.address3 = replaceHalfToFull(new_user.address3)"
                       placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.address3"
                                   v-if="validation_errors.address3"></validation-errors>
              </div>

              <div class="input_set">
                <div class="label">パスワード</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_type ? 'text' : 'password'" v-model="new_user.password" class="password"
                         @input="new_user.password = replaceFullToHalf(new_user.password)"
                         placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check01" type="checkbox" v-model="password_type" value="1">
                    <label for="check01">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password"
                                   v-if="validation_errors.password"></validation-errors>
              </div>
              <div class="input_set">
                <div class="label">確認のためにもう一度入力</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_confirmation_type ? 'text' : 'password'"
                         @input="new_user.password_confirmation_type = replaceFullToHalf(new_user.password_confirmation_type)"
                         v-model="new_user.password_confirmation" class="password" placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check02" type="checkbox" v-model="password_confirmation_type" value="1">
                    <label for="check02">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password_confirmation"
                                   v-if="validation_errors.password_confirmation"></validation-errors>
              </div>

            </div>

            <div class="input_set">
              <div class="checkbox">
                <input id="check03" type="checkbox" v-model="new_user.enable_primary_address" value="1"
                       v-on:change="enablePrimaryAddressClicked()">
                <label for="check03"
                       class="bold blue">郵便物を別住所で受け取りたい方、海外在住で日本の住所がある方はチェックを入れて、郵便物等の送付先住所指定フォームに別住所を指定してください</label>
                <p class="pl25">※送付先は日本国内に限ります。</p>
              </div>
            </div>

            <div class="reg_set option" v-show="new_user.enable_primary_address">
              <h3 class="min_ttl">郵便物等の送付先住所指定（任意：登録ご住所と同じ場合は入力は不要です）</h3>
              <p class="reg_lead">
                オンラインショップの商品や複眼経済塾からの各種お知らせなど、塾生登録住所以外への送付を希望する場合はご入力ください。<br>塾生登録住所以外で郵便物などを送付する住所のご希望がない場合は入力をスキップしてください。<br><br>
                <span class="blue bold" v-if="false">「*」のついている箇所は必須項目です。</span>
              </p>

              <div class="gray_box">
                <div class="input_set addr">
                  <div class="label">郵便番号</div>
                  <p>（半角数字）</p>
                  <div class="input_fields flex">
                    <div class="field">
                      <input type="text" v-model="new_user.opt_zip" v-bind:class="{error: validation_errors.opt_zip}"
                             @input="new_user.opt_zip = replaceFullToHalf(new_user.opt_zip)"
                             name="opt_zip" placeholder="例：100-1234">
                      <validation-errors :errors="validation_errors.opt_zip"
                                         v-if="validation_errors.opt_zip"></validation-errors>
                    </div>
                    <div class="field">
                      <button type="button" class="ajaxzip3 option" v-on:click="optZipSearch">郵便番号から住所を取得
                      </button>
                    </div>
                  </div>
                </div>

                <div class="input_set addr">
                  <div class="label">都道府県名</div>
                  <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>
                  <PrefectureSelect :prefecture="new_user.opt_prefecture" name="opt_prefecture"
                                    v-bind:class="{error: validation_errors.opt_prefecture}"
                                    v-on:changed="optPrefectureChanged"/>
                  <validation-errors :errors="validation_errors.opt_prefecture"
                                     v-if="validation_errors.opt_prefecture"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">市区町村</div>
                  <input type="text" v-model="new_user.opt_address1"
                         @input="new_user.opt_address1 = replaceHalfToFull(new_user.opt_address1)"
                         v-bind:class="{error: validation_errors.opt_address1}" name="opt_address1"
                         placeholder="例：新宿区">
                  <validation-errors :errors="validation_errors.opt_address1"
                                     v-if="validation_errors.opt_address1"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">地名・番地</div>
                  <input type="text" v-model="new_user.opt_address2"
                         @input="new_user.opt_address2 = replaceHalfToFull(new_user.opt_address2)"
                         v-bind:class="{error: validation_errors.opt_address2}" placeholder="例：３丁目１３番地">
                  <validation-errors :errors="validation_errors.opt_address2"
                                     v-if="validation_errors.opt_address2"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">建物名・部屋番号<span></span></div>
                  <input type="text" v-model="new_user.opt_address3"
                         @input="new_user.opt_address3 = replaceHalfToFull(new_user.opt_address3)"
                         v-bind:class="{error: validation_errors.opt_address3}" placeholder="例：複眼ビル１１１号室">
                  <validation-errors :errors="validation_errors.opt_address3"
                                     v-if="validation_errors.opt_address3"></validation-errors>
                </div>

              </div>
            </div>

            <p class="alnC mb20">
              すべての項目を確認の上でこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="次に進む">
            </div>
          </div>
        </form>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'
import PrefectureSelect from "@/components/PrefectureSelect";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
    FormErrorBox
  },
  data() {
    if (!this.$store.state.user) {
      this.$router.push({
        name: 'UsersRegistration',
        params: this.$route.params
      })
      return
    }
    let _new_user = {}
    for (const [key, value] of Object.entries(this.$store.state.user)) {
      _new_user[key] = value
    }
    return {
      request_exec: false,
      new_user: this.$store.state.user,
      validation_errors: {},
      errors: null,
      password_type: false,
      password_confirmation_type: false,
      show_rank: false,
      rank_name: null,
      rank_payment: null,
      no_option: this.$store.state.user.rank !== 'rank_regular_year',
      show_shikihou: (this.$store.state.user.rank !== 'rank_preparatory_month' && this.$store.state.user.rank !== 'rank_regular_plus_year' && this.$store.state.user.rank !== 'rank_regular_plus_month'),
      show_shikiho_account: this.$store.state.user.shikiho_account !== null
    }
  },

  mounted() {
    this.updateRankGuide()
    window.$('.acc_menu .acc_cts').hide()
    window.$('.top_cts.acc_menu .acc_cts').show()
    window.$('.acc_menu .acc_tab').on('click', function () {
      window.$(this).next('.acc_cts').slideToggle()
      window.$(this).toggleClass('open')
    });
  },
  methods: {
    changeShikihoAccount() {
      if (!this.show_shikiho_account) {
        this.new_user.shikiho_account = null
      }
    },
    showShikihoAccount() {
      const shikiho = this.$store.state.user.shikiho
      const rank = this.$store.state.user.rank
      // console.log(rank, shikiho)
      return rank === 'rank_regular_plus_year' ||
          rank === 'rank_regular_plus_month' ||
          shikiho === 'rank_regular_shikiho_premium_year' ||
          shikiho === 'rank_regular_shikiho_basic_year'
    },
    rankChange() {
      setTimeout(() => {
        this.new_user.shikiho = ''
        if (this.new_user.rank === 'rank_regular_year' || this.new_user.rank === 'rank_regular_month') {
          setTimeout(() => {
            window.$('#course04').prop('checked', true)
          }, 10)
        } else {
          setTimeout(() => {
            window.$('#course04').prop('checked', false)
          }, 10)
        }
        this.no_option = this.new_user.rank !== 'rank_regular_year'
        this.show_shikihou = (this.new_user.rank !== 'rank_preparatory_month' && this.new_user.rank !== 'rank_regular_plus_year' && this.new_user.rank !== 'rank_regular_plus_month')
        this.updateRankGuide()
        this.$forceUpdate();
      }, 100)
    },

    // オプション変更
    shikihoChange() {
      console.log(this.new_user.shikiho);
    },

    prevClicked() {
      this.$router.push({
        name: 'UsersConfirmationPage3',
        params: this.$route.params
      })
    },
    nextClicked(event) {
      if (this.request_exec) return
      this.request_exec = true

      event.preventDefault()
      this.validation_errors = {}
      this.errors = null

      const rank_bk = this.new_user.rank
      const shikiho_bk = this.new_user.shikiho
      if (shikiho_bk && shikiho_bk !== 'none') {
        this.new_user.rank = shikiho_bk
      }

      if (this.new_user.shikiho_account && this.new_user.shikiho_account.match('@toyokeizai.net')) {
        this.new_user.shikiho_account = this.new_user.shikiho_account.split('@')[0]
      }

      this.new_user.password_validates = true

      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}users/create.json`, {
            user: this.new_user,
            campaign_code: this.new_user.campaign_code
          })
          .then(response => {
            this.$router.push({
              name: 'UsersConfirmationFinish',
              params: {token: response.data.token}
            })
          })
          .catch((error) => {
            if (error.response.data.validation_errors) {
              this.new_user.rank = rank_bk
              this.new_user.shikiho = shikiho_bk

              this.scrollTop()
              this.errors = true
              this.validation_errors = error.response.data.validation_errors
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
            this.$forceUpdate();
          })
          .finally(() => {
            this.finishUpload()
            this.request_exec = false
          })
    },
    prefectureChanged(prefecture) {
      this.new_user.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.new_user.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.new_user.zip) {
        this.addressSearch(this.new_user.zip)
            .then(data => {
              this.new_user.prefecture = data.pref
              this.new_user.address1 = data.address
              this.$forceUpdate()
            })
      }
    },
    enablePrimaryAddressClicked() {
      if (this.new_user.enable_primary_address) {
        this.new_user.opt_zip = this.new_user.zip
        this.new_user.opt_prefecture = this.new_user.prefecture
        this.new_user.opt_address1 = this.new_user.address1
        this.new_user.opt_address2 = this.new_user.address2
        this.new_user.opt_address3 = this.new_user.address3
        this.$forceUpdate()
      }
    },
    optZipSearch() {
      if (this.new_user.opt_zip) {
        this.addressSearch(this.new_user.opt_zip).then(data => {
          this.new_user.opt_prefecture = data.pref
          this.new_user.opt_address1 = data.address
          this.$forceUpdate()
        })
      }
    },
    updateRankGuide() {
      this.rank_name = {
        rank_regular_plus_month: '本科プラス',
        rank_regular_plus_year: '本科プラス',
        rank_regular_month: '本科',
        rank_regular_year: '本科',
        rank_regular_shikiho_premium_year: '本科',
        rank_regular_shikiho_basic_year: '本科',
        rank_preparatory_month: '予科',
      }[this.new_user.rank]

      this.rank_payment = {
        rank_regular_plus_month: '月額払い',
        rank_regular_plus_year: '年額払い',
        rank_regular_month: '月額払い',
        rank_regular_year: '年額払い',
        rank_regular_shikiho_premium_year: '年額払い',
        rank_regular_shikiho_basic_year: '年額払い',
        rank_preparatory_month: '月額払い',
      }[this.new_user.rank]
    }
  }
}
</script>
